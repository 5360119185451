
// BootstrapのJavaScript側の機能を読み込む
import "bootstrap";

// スタイルシートを読み込む
import "./index.scss";

// jquery読み込み
import $ from 'jquery';

// 下スクロール中はヘッダを隠す、上スクロール中はヘッダを表示する
/*
// 時間経過によってヘッダーが再表示されるパターン
$(function(){
    var scrollTimer;
    var header = $('header');
    $(window).on('scroll', function (e) {
        var isTop = $(window).scrollTop() === 0;
        clearTimeout(scrollTimer);
        header.toggleClass('hide', !isTop);

        scrollTimer = setTimeout(function() {
            header.removeClass('hide');
        }, 500);
    })
});
*/
$(function(){
    var pos = 0;
    var header = $('header');
    
    $(window).on('scroll', function(){
      if($(this).scrollTop() < pos ){
        //上スクロール時の処理
        header.removeClass('hide');
      }else{
        //下スクロール時の処理
        header.addClass('hide');
      }
      pos = $(this).scrollTop();
    });
});


// ナビゲーションのドロップダウンメニュー押下時にメニューが閉じるようにする
$('.navbar-nav>li>a , .dropdown-menu>a').on('click', function(){
    if(this.id != 'navbarDropdown'){
      $('.navbar-collapse').collapse('hide');
    }
});

// TOPページ　BUSINESSカテゴリhover時に要素切り替え
$(function() {
    $('.material').on({
        'mouseenter': function(){
            $('.material').stop().fadeOut('slow').css("display","none");
            $('.material-hover').stop().fadeIn('slow').css("display","block");
        }
    });
    $('.material-hover').on({
        'mouseleave': function(){
            $('.material-hover').stop().fadeOut('slow').css("display","none");
            $('.material').stop().fadeIn('slow').css("display","block");
        }
    });
    $('.original').on({
        'mouseenter': function(){
            $('.original').stop().fadeOut('slow').css("display","none");
            $('.original-hover').stop().fadeIn('slow').css("display","block");
        }
    });
    $('.original-hover').on({
        'mouseleave': function(){
            $('.original-hover').stop().fadeOut('slow').css("display","none");
            $('.original').stop().fadeIn('slow').css("display","block");
        }
    });
    $('.processing').on({
        'mouseenter': function(){
            $('.processing').stop().fadeOut('slow').css("display","none");
            $('.processing-hover').stop().fadeIn('slow').css("display","block");
        }
    });
    $('.processing-hover').on({
        'mouseleave': function(){
            $('.processing-hover').stop().fadeOut('slow').css("display","none");
            $('.processing').stop().fadeIn('slow').css("display","block");
        }
    });
});
